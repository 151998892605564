// import {showExistingSimulationInCartPopUp, simulationAlreadyInCart} from "../../Core/ShoppingCart/shopping_cart.js";
const ShoppingCart = require("./../../Core/ShoppingCart/shopping_cart");
const ContribVisualiser = require("./../../Core/Simulator/contrib_prevusualize");

$(document).ready(function () {
    /*ELIGIBLE WORK FIELD RECUPERATION*/
    // Categories vars
    var catContainer = $('#categories-container');
    var catTrigger = $('select[name*=eligible_category]');
    var currentValue = lower_first_word(catTrigger.find('option').first().text());
    var newValue;

    var workInput = $('select[name*=eligible_work]');
    var categoryId = catTrigger.find('option:first').attr('value');

    // Work vars
    var workTrigger = $('select[name*=eligible_work]');
    var workContainer = $('.input-container');
    var currentId = workTrigger.find('option:selected').attr('value');

    // Form vars
    var submitTrigger = $('input[type*=submit]');
    var form = $('form[name*=front_simulation]');
    var inputContainers;
    var canSubmit;

    var enrCdpContainer = $('.CDP_ENR').removeClass('col-md-6');
    var enrCombCdpContainer = $('.CDP_ENR_COMB').removeClass('col-md-6');

    var enrContainer = $('#enr_container');
    var enrCombContainer = $('#enr_comb_container');

    var hideInputsConditions = [
        {
            cond: function () {
                if ($.inArray(parseInt(workTrigger.find('option:selected').attr('value')), [322]) === -1)
                    return false;
                return true;
            },
            hideList: ['RRC_ERR']
        },
        {
            cond: function () {
                if ($.inArray(parseInt(workTrigger.find('option:selected').attr('value')), [115, 116]) === -1)
                    return false;

                var LOG = $('form[name*=front_simulation]').find("input[name*=LOG]:checked");
                var logVal = LOG.length > 0 ? LOG.val() : null;
                return logVal == 1;
            },
            hideList: []
        },
        {
            cond: function () {
                if (workTrigger.find('option:selected').attr('value') != 204)
                    return false;

                var LOG = $('form[name*=front_simulation]').find("input[name*=LOG]:checked");
                var logVal = LOG.length > 0 ? LOG.val() : null;
                return logVal == 1;
            },
            hideList: []
        },
        {
            cond: function () {
                if ($.inArray(parseInt(workTrigger.find('option:selected').attr('value')), [111]) === -1)
                    return false;
                return true;
            },
            hideList: ["P3_COPE"]
        },
    ];

    var hideWorkConditions = [
        // Résidentiel
        {
            'triggerName': 'LOG',
            'cond': function (val) {
                return val == 2;
            },
            'hideList': [113, 114],
            "message": "<p>Ces travaux ne sont pas éligibles en Appartement.</p>"
        },
        {
            'triggerName': 'P3_ETAS',
            'cond': function (val) {
                if (!val)
                    return;

                var typePac = $('form[name*=front_simulation]').find("input[name*=P3_TYPE_PAC]:checked");
                var typePacVal = typePac.length > 0 ? typePac.val() : null;


                var alertMsg = null;
                var returnresult = false;

                if (val.length > 0) {
                    if (typePacVal == 0) {
                        alertMsg = "Ces travaux ne sont pas éligibles pour les caractéristiques techniques que vous avez indiquées (Efficacité énergétique saisonnière doit être supérieure ou égale à 126)";
                        returnresult = val < 126;
                    } else if (typePacVal == 1) {
                        alertMsg = "Ces travaux ne sont pas éligibles pour les caractéristiques techniques que vous avez indiquées (Efficacité énergétique saisonnière doit être supérieure ou égale à 111)";
                        returnresult = val < 111;
                    }
                }
                if (alertMsg && returnresult) {
                    alert(alertMsg);
                    $('form[name*=front_simulation]').find("input[name*=P3_ETAS]").val('');
                    typePac.removeAttr('checked');
                }
                return returnresult;
            },
        }
    ];

    this.inputs = [];
    this.works = [];
    var pthis = this;


    function checkEligibility() {
        for (var i = 0; i < hideWorkConditions.length; i++) {
            var conf = hideWorkConditions[i];
            if (!conf) continue;

            var triggerName = conf.triggerName;
            var condHandler = conf.cond;

            var trigger = $("input, select").filter("[name*='" + triggerName + "']");

            // On prend le type de trigger en considération
            var type = trigger.attr('type');

            let value;

            if (type && type.toLowerCase() == 'radio') {
                value = trigger.filter(":checked").val();
            } else {
                value = trigger.val();
            }

            // Vérifions si la condition est remplie => si elle l'est on devra masquer tous les éléments listés
            var doHide = condHandler(value);

            if (doHide){
                if($.inArray(parseInt(workTrigger.find('option:selected').attr('value')), conf.hideList) != -1) {
                    var popupContainer = $('<div>');
                    popupContainer.append(conf.message);
                    popupContainer.dialogMp();
                }
            }
        }
    };

    function applyInputFilters() {
        var inputsToHide = [];
        for (var i = 0; i < hideInputsConditions.length; i++) {
            var conf = hideInputsConditions[i];
            var doHide = conf.cond();
            if (doHide)
                inputsToHide = inputsToHide.concat(conf.hideList);
        }
        return inputsToHide;
    };

    function createOptions() {
        workInput.empty();
        var worksToHide = [];
        $.each(pthis.works, function (key, value) {
            if ($.inArray(parseInt(value.id), worksToHide) == -1) {
                var option = $('<option>')
                    .attr('value', value.id)

                    .text(value.title)
                ;

                if (currentId == value.id) {
                    option.attr('selected', 'selected')
                }

                workInput.append(option);
            }
        });
    }

    catTrigger.change(function () {
        categoryId = $(this).val();
        currentId = workTrigger.find('option:selected').attr('value');

        newValue = lower_first_word($(this).find('option:selected').text());
        newValue = newValue.normalize('NFD').replace(/[\u0300-\u036f]/g, "");

        if (currentValue != newValue) {
            updateContainer(newValue);
        }

        $.ajax(Routing.generate('api_get_category_works', {
            type: 'particulier-3p',
            category_id: categoryId
        }))
            .done(function (data) {
                pthis.works = data;
                createOptions();
                workTrigger.change();
            });

    });

    function updateContainer(value) {
        var currentClass = '.' + currentValue;
        catContainer.find(currentClass)
            .removeClass(currentValue)
            .addClass(value);

        currentValue = value;
    }

    function lower_first_word(str) {
        var asArray = str.split(" ");
        return asArray[0].toLowerCase();
    }

    workTrigger.change(function () {
        currentId = $(this).find('option:selected').attr('value');

        //bar-th-163 be closed on 01/01/2025
        // var date_31_12_2024 = new Date(2024,11,31); // month between 0-11
        // if (currentId == 317 &&  new Date() < date_31_12_2024) {
        //     canSubmit = false;
        //     $('#bar-th-163-closing-error-modal').dialogMp();
        // }
        //

        $.ajax(Routing.generate('api_get_work_fields', {
            slug: 'particulier-3p',
            id: currentId
        }))
            .done(function (data) {
                pthis.inputs = data;
                if (isBarTh164()) {
                    enrCdpContainer.show();
                    enrCombCdpContainer.show();
                    enrContainer.show();
                    enrCombContainer.show();
                } else if (hasEnrCdpConditions()) {
                    /*enrContainer.before(enrCdpContainer.show()).hide().addClass('no-visibility');
                    enrCombContainer.before(enrCombCdpContainer).hide().addClass('no-visibility');
                    enrCdpContainer.change();*/
                    enrCdpContainer.show();
                    enrCombCdpContainer.show();
                    enrContainer.hide();
                    enrCombContainer.hide();
                } else {
                    /*if (!enrCombCdpContainer.hasClass('no-visibility') && !enrCdpContainer.hasClass('no-visibility')) {
                        enrCdpContainer.before(enrContainer.show()).hide().addClass('no-visibility');
                        enrCombCdpContainer.before(enrCombContainer).hide().addClass('no-visibility');
                        enrContainer.change();
                    }*/
                    enrCdpContainer.hide();
                    enrCombCdpContainer.hide();
                    enrContainer.show();
                    enrCombContainer.show();
                }

                displayInputs();
                checkEligibility();
            });
    });

    function hasEnrCdpConditions() {
        if ($.inArray(parseInt(workTrigger.find('option:selected').attr('value')), [111, 113, 114, 227, 306, 307, 338, 339]) != -1)
            return true;

        return false;
    }

    function isBarTh164() {
        if (parseInt(workTrigger.find('option:selected').attr('value')) == 327)
            return true;

        return false;
    }

    function displayInputs() {
        resetInputs();

        var inputsToHide = applyInputFilters();

        $.each(pthis.inputs, function (key, value) {
            if ($.inArray(value.name, inputsToHide) == -1) {

                let inputName = "front_simulation[" + value.name + "]";

                var input = $('input[name="' + inputName + '"]');

                if (!input.length)
                    input = $('select[name="' + inputName + '"]');

                input.closest('.field-container').removeClass('no-visibility');

            }
        });
    }

    function resetInputs() {
        $.each(workContainer.find('.field-container'), function () {
            $(this).addClass('no-visibility');
        });
    }

    // function dossierRefCheck() {
    //     //barth174/175
    //     if (currentId == 341 || currentId == 342) {
    //         let dossierRefInput = $('[name$="FIRST_DOSSIER_REF]"]');
    //         let dossier_ref = dossierRefInput.val().trim();
    //         if (dossier_ref && $('[name$="NB_ETAPE]"]:checked').val() == 2) {
    //             $('input[type=submit]').after('<img class="loader" style="display:unset;" src="https://cdn.v2.primesenergie.fr/images/16-ajax-loader.gif" alt=""/>');
    //             let data = {dossier_ref: dossier_ref}
    //             var url = Routing.generate('api_check_first_dosser_reference');
    //             $.ajax({
    //                 url: url,
    //                 data: data,
    //                 type: "POST",
    //                 async: false,
    //                 success: function (data, status) {
    //                     console.log('success');
    //                     return =  true;
    //                 },
    //                 error: function (data, jqXHR, status, errorThrown) {
    //                     $('input[type=submit]').siblings('.loader').remove();
    //                     if (dossierRefInput.siblings('.error-msg').length > 0) {
    //                         dossierRefInput.siblings('.error-msg').html(JSON.parse(data.responseText).error);
    //                     } else {
    //                         dossierRefInput.after('<p class="error-msg" style="top: 45px; left: 0;">'+JSON.parse(data.responseText).error+'</p>');
    //                     }
    //                     dossierRefInput.parent().parent().addClass('error');
    //                     dossierRefInput.focus();
    //                     console.log('error');
    //                     dossierRefFlag = false;
    //                 },
    //             })//end ajax
    //         }
    //     }
    // }

    form.find('input[type=submit]').click(function (e) {
        var itemId = window.location.href.split('/').pop();

        if (canSubmit) {
            return;
        } else {
            e.preventDefault();
            // dossierRefCheck();
            let response = ShoppingCart.simulationAlreadyInCart(currentId);

            if (!response.exists || response.item_id == itemId) {
                inputContainers = $(".field-container.no-visibility");

                $.each(inputContainers, function () {
                    var conditions = $(this).has('input[name*=_token]').length + $(this).has('input[name*=quotation_date]').length +
                        $(this).has('input[name*=P3_ETAS]').length + $(this).has('input[name*=P3_COPE]').length
                        + $(this).has('input[name*=CDP_ENR_COMB]').length + $(this).has('input[name*=ENR_COMB]').length;
                    if (conditions == 0) {
                        $(this).attr('disabled');
                        $(this).remove();
                    }
                });

                canSubmit = true;
                $(this).trigger('click');

            } else {
                canSubmit = false;
                ShoppingCart.showExistingSimulationInCartPopUp(response.edit_url, response.eligible_work_title);
            }
        }
    });

    resetInputs();
    catTrigger.change();
    workTrigger.change();
    checkEligibility();

    // form.find('select, input[type=radio], input[type=text]').change(function () {
    //     // displayInputs();
    // });

    $("[name*='LOG'],[name*='P3_ETAS'],[name*='P3_TYPE_PAC']").change(function () {
        createOptions();
        workTrigger.change();
    });

    /*DEPT MAP SELECTOR*/
    var deptSelect = $('select[name*=DEPT]');
    deptSelect.change(function () {
        if ($(this).val() == 0 || $(this).val() > 96) {
            $('#mb-current').attr('class', 'mb');
        } else {
            var text = $.trim(deptSelect.find("option:selected").text());
            var dept = text.substring(0, 2);
            $("#frenchMap").find("a").each(function () {
                if ($(this).data('departements').indexOf(dept) >= 0) {
                    var className = $(this).attr('class') + "b";
                    if ($(this).attr('class'))
                        $('#mb-current').attr('class', 'mb ' + className);
                }
            });
        }
    });
    deptSelect.change();

    if ($('input[name*=quotation_signed]:checked').val() == 1) {
        if ($('#quotation-signed-error-modal').length > 0) {
            setTimeout(function () {
                $('#quotation-signed-error-modal').dialogMp();
            }, 1000);
        }
    }

    /*ENR_COMB_CDP DISPLAY SCRIPT*/
    enrCdpContainer.change(function () {
        var currentValueCdp = enrCdpContainer.find('input[name="front_simulation[CDP_ENR]"]:checked').val();
        if (currentValueCdp == 1) {
            enrCombCdpContainer.hide();
        } else {
            enrCombCdpContainer.show();
        }
    }).change();

    /*ENR_COMBDISPLAY SCRIPT*/
    enrContainer.change(function () {
        var currentEnrValue = enrContainer.find('input[name="front_simulation[ENR]"]:checked').val();
        if (currentEnrValue == 1) {
            enrCombContainer.hide().addClass('no-visibility');
        } else {
            enrCombContainer.show().removeClass('no-visibility');
        }
    }).change();

    // ajout du placeholder sur tous les inputs avec unités
    $('form input[type=text][data-unit]').each(function () {
        var unitSpan = $('<span class="text m2">').text($(this).data('unit'));
        $(this).after(unitSpan);
    });

    $('.app-visualise-cdp-contrib-pdf').on('click', function () {
        let $form = $('form[name=app_user_registration]');
        let itemId = $(this).data('item-id');
        ContribVisualiser.visualiseCdpContribPdf($form, 'app_visualise_cdp_contrib_pdf', itemId);
    });
});